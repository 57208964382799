/*
This is where you write custom SASS
*/

body[data-ajax-transitions="true"]
#ajax-loading-screen[data-method="standard"]
	.loading-icon {
		display: block;
		opacity: 1;
		transition: 0.3s ease;
}
#ajax-loading-screen {
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	position: fixed;
	transition: 0.3s ease;
	top: 0;
	left: 0;
	display: none;
	z-index: 1000000000;
}
.loading-icon.spin,
.portfolio-loading.spin,
.pp_loaderIcon.spin,
.default-loading-icon.spin {
	animation: .3s ease 0s normal none
}
#ajax-loading-screen .loading-icon {
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	position: absolute;
	opacity: 0;
	top: 50%;
	left: 0;
    right: 0;
}
@keyframes fade_in_logo {
	0% {
        transform: scale(.8);
        opacity: .8;
	}
	50% {
        transform: scale(.9);
        opacity: .9;
	}
	100% {
        transform: scale(1);
        opacity: 1;
	}
}
.ctn-preloader {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9000;
}
.ctn-preloader .animation-preloader {
	z-index: 1000;
}
.ctn-preloader .animation-preloader .spinner {
	-webkit-animation: spinner 1s infinite linear;
	animation: spinner 1s infinite linear;
	border-radius: 50%;
	border: 1px solid rgba(99, 102, 151, 0.2);
	border-top-color: $primary;
	height: 65px;
	margin: 0 auto 3.5em auto;
	width: 65px;
}
.ctn-preloader.dark .animation-preloader .spinner {
	border-color: rgba(99, 102, 151, 0.2);
	border-top-color: $primary;
}
.ctn-preloader .loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 0;
	z-index: 1;
	pointer-events: none;
}
.ctn-preloader .loader .row {
	height: 100%;
}
.ctn-preloader .loader .loader-section {
	padding: 0px;
}
.ctn-preloader.loaded .animation-preloader {
	opacity: 0;
	-webkit-transition: 0.3s ease-out;
	-o-transition: 0.3s ease-out;
	transition: 0.3s ease-out;
}
.ctn-preloader.loaded .loader-section .bg {
	width: 0;
	-webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	-o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}
@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}
@keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}
@media screen and (max-width: 767px) {
	.ctn-preloader .animation-preloader .spinner {
		height: 65px;
		width: 65px;
	}
}
@media screen and (max-width: 500px) {
	.ctn-preloader .animation-preloader .spinner {
		height: 65px;
		width: 65px;
	}
}

@media screen and (max-width:992px) {
	section.contact-section .panel-image {
		background-position: 100% 0 !important;
		background-repeat: no-repeat !important;
		height: 65vw;
	}
}
@media (max-width: 768px) {
	section.contact-section .panel-image {
		height: 75vw;
	}
}
@media (max-width: 576px) {
	section.contact-section .panel-image {
		height: 85vw;
	}
}
@media (max-width: 991.98px){
	section.contact-section {
		padding: 40% 0 60px;
		height: auto!important;
	}
}
@include media-breakpoint-down(md) {
	div#navCollapseElement:not(.show) {
		pointer-events: none;
	}
}
.contact-card,
.cta-wrap {
	box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.35),
		inset 0 0 0 1.5px rgba(255, 255, 255, 0.46);
}
.btn {
	border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.container-fluid.container-max-width {
	max-width: 1570px !important;
}

@media (max-width: 1600px) {
	.display-1 {
		font-size: calc(1.5rem + 4.5vw);
	}

	.display-2 {
		font-size: calc(1.325rem + 3.1vw);
	}
}

.tooltip {
	top: 30px !important;
	margin-left: -16px !important;
	.tooltip-inner {
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 4px;
		background-color: $gray-900 !important;
	}
}

header.header-section {
	@include media-breakpoint-down(md) {
		&:before {
			content: none !important;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 33%;
		background: white;
	}

	.menu-toggle {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 16px;
		width: 44px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			transition: 0.3s ease;
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.2) !important;
			svg {
				color: #262729 !important;
			}
		}
	}

	.header-btn {
		width: 200px;
		@include media-breakpoint-down(xl) {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border-radius: $btn-border-radius;
			&[id*="PhoneButton_hrefPhone"] svg ~ * {
				display: none;
			}
		}
	}
}

nav {
	ul#main-menu {
		> li {
			> a {
				@include media-breakpoint-up(lg) {
					&.current,
					&:hover,
					&.highlighted {
						background-color: rgba(0, 0, 0, 0.08) !important;
					}
				}
			}
		}
		ul {
			li {
				a {
					@include media-breakpoint-down(lg) {
						&.current,
						&:hover,
						&.highlighted {
							background-color: transparent !important;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		#navCollapseElement {
			background-image: linear-gradient(180deg, #fff 0%, #f7f2f0 100%);
		}
	}
	.icon {
		~ .dropdown-menu {
			right: 0;
			left: auto;
			a {
				border-radius: 8px;
			}
		}
	}
}

section.hero-section {
	height: 690px;
	min-height: 175px;

	@include media-breakpoint-down(xl) {
		& {
			height: 625px !important;
		}
	}
	@include media-breakpoint-down(lg) {
		& {
			height: 500px !important;
		}
	}
	@include media-breakpoint-down(md) {
		& {
			height: 400px !important;
		}
	}
	@include media-breakpoint-down(sm) {
		& {
			height: 300px !important;
		}
	}
	@media (max-width: 576px) {
		& {
			height: 250px !important;
		}
	}
	@media (max-width: 480px) {
		.display-1 {
			font-size: 30px;
			margin-top: 50px;
		}
		& {
			height: 175px !important;
		}
	}
	@media (max-width: 320px) {
		.display-1 {
			font-size: 25px;
		}
		& {
			height: 165px !important;
		}
	}
}

section.addr-section {
	.offices {
		span.separator:nth-of-type(2) + span ~ * {
			display: none;
		}
		.separator {
			color: rgba($gray-800, 0.2);
		}
	}
	.addr-btn {
		width: 200px;
	}

	.phone-dropdown-menu {
		transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
		display: block;
		opacity: 0;
		visibility: hidden;
		padding: $spacer * 0.5;
		background: transparent;
		margin-top: -1.5rem !important;
		max-width: 100% !important;
		min-width: 200px !important;
		border: 0.5px solid rgba(0, 0, 0, 0.2) !important;
		margin-right: -5px;

		.font-weight-bold {
			font-weight: 400 !important;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			top: 5%;
			bottom: 25%;
			left: 25%;
			right: 25%;
			transition: 0.3s ease;
			border-radius: $border-radius;
			background-color: #fff !important;
			opacity: 0;
		}

		&.show {
			opacity: 1;
			visibility: visible;
			top: 30px !important;

			&:before {
				right: 0;
				left: 0;
				top: 0;
				bottom: 0;
				opacity: 1;
			}

			ul {
				@for $i from 1 to 10 {
					a:nth-child(#{$i}) {
						opacity: 1;
						visibility: visible;
						transform: translate3d(0, 0, 0);
						transition-delay: 0.2 + ($i * 0.025s);
					}
				}
			}
		}

		ul {
			padding: 0;
			margin: 0;
			a {
				font-size: $nav-font-size;
				line-height: 1.4em;
				transition: 0.3s ease;
				opacity: 0;
				visibility: hidden;
				transform: translate3d(0, 1rem, 0);
				display: block;
				span {
					color: $gray-800 !important;
				}

				.row {
					padding: $spacer * 0.5 $spacer * 0.25 $spacer * 0.5 $spacer;
					position: relative;
					&:before {
						content: "";
						display: block;
						position: absolute;
						z-index: -1;
						transition: 0.3s ease;
						border-radius: $border-radius;
						background-color: rgba(0, 0, 0, 0.08) !important;
						opacity: 0;
					}
				}

				&:hover {
					.row {
						&:before {
							right: 0;
							left: 0;
							top: 0;
							bottom: 0;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

section.content-section {
	.cta-wrap {
		border-radius: $border-radius-lg;
		min-height: 545px;
		width: 410px;
		max-width: 100%;
		.btn {
			max-width: 165px;
		}
		.cta-inner-wrap {
			bottom: 0;
			left: 0;
			.cta-text {
				font-size: 19px;
			}
		}
		@include media-breakpoint-down(lg) {
			& {
				width: 350px;
			}
		}
	}
}

section.contact-section {
	padding: 200px 0px;
	.contact-card {
		.form-group {
			margin-top: 0px !important;
			margin-bottom: 8px !important;
		}
		padding: 60px 55px;
		max-width: 515px;
		border-radius: $border-radius-lg;
		.btn {
			max-width: 165px;
		}
		@include media-breakpoint-down(lg) {
			& {
				max-width: 430px !important;
				padding: 50px 30px !important;
			}
		}
	}
	@include media-breakpoint-down(md) {
		& {
			height: auto !important;
			padding: 150px;
		}
	}
	@include media-breakpoint-down(md) {
		& {
			height: auto !important;
			padding: 330px 0 60px;
		}
	}
	.short-message {
		font-size: 21px;
	}
}

section.testimonials {
	.owl-nav {
		position: absolute;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		left: 0;
		right: 0;
		.owl-prev {
			transition: 0.3s ease;
			left: 55px;
			position: absolute;
			color: rgba(255, 255, 255, 0.5) !important;
			font-size: 40px !important;
			margin: 0px !important;
			padding: 0px !important;
			background: rgba(0, 0, 0, 0) !important;
			display: block !important;
			cursor: pointer;
			border: none;
			outline: none;
			border-radius: 0 !important;
			&:hover {
				color: rgba(255, 255, 255, 1) !important;
			}
			&:focus {
				border: none;
				outline: none;
			}
		}
		.owl-next {
			transition: 0.3s ease;
			right: 55px;
			position: absolute;
			color: rgba(255, 255, 255, 0.5) !important;
			font-size: 40px !important;
			margin: 0px !important;
			padding: 0px !important;
			background: rgba(0, 0, 0, 0) !important;
			display: block !important;
			cursor: pointer;
			border: none;
			outline: none;
			border-radius: 0 !important;
			&:hover {
				color: rgba(255, 255, 255, 1) !important;
			}
			&:focus {
				border: none;
				outline: none;
			}
		}
		@media screen and (max-width: 1500px) {
			.owl-next {
				right: 20px;
			}
			.owl-prev {
				left: 20px;
			}
		}

		@include media-breakpoint-down(lg) {
			& {
				position: relative;
				top: auto !important;
				transform: none !important;
				left: auto !important;
				right: auto !important;
				// .owl-prev {
				// 	left: 5px !important;
				// }
				// .owl-next {
				// 	left: 40px !important;
				// }
			}
		}
	}
}

footer {
	span.footcolor {
		a {
			color: #fff;
			&:hover {
				color: #d9d9d9 !important;
			}
		}
	}
	.footer-company-name {
		&[id*="_CompTitle1"] {
			display: inline !important;
		}
	}
}

//interior custom scss
body.interior {
	&.page-type-3 {
		.card {
			.form-group {
				margin-top: 0px !important;
				margin-bottom: 8px !important;
			}
			padding: 65px 55px;
			background: $gray-200;
			border-radius: $border-radius-lg;
			max-width: 515px;
			@include media-breakpoint-down(lg) {
				& {
					max-width: 430px !important;
				}
			}

			p.h3 {
				font-size: 21px;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				color: $gray-600;
			}
			[id*="HeaderInfoDiv"] {
				font-size: 21px;
				font-weight: 400;
				color: #38302d;
				line-height: 32px;
			}
			label {
				display: none;
			}
			[id*="btnSend"] {
				max-width: 165px;
				width: 100%;
			}
		}
	}
	&.page-type-26 {
		.page-content .container {
			max-width: 960px;
		}
	}
	&.page-type-10 {
		[style*="FONT-SIZE: 12px"] {
			font-size: 15px !important;
		}
	}
}
